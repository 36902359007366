import { Router } from '@angular/router';

export class HealthCheckService {
    private isOutOfService = false;

    constructor(
        private readonly router: Router,
        private readonly outOfServicePath: string,
    ) {}

    setOutOfService(value: boolean): void {
        this.isOutOfService = value;
        if (value) {
            this.router.navigate([this.outOfServicePath], {
                skipLocationChange: true,
            });
        }
    }

    getOutOfService(): boolean {
        return this.isOutOfService;
    }
}
